import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type {
  ClientsInfoData,
  Users,
  UsersRequestParams,
  UserDetails,
  UserBalancesData,
  UserTransactionLimit,
  UserTransferBalanceData,
  UserTransferReason,
  CurrentUserTransactionLimit,
  CurrentUserTransactionLimitResponse
} from './schema';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import clientsInfoStateDefaults from '@/store/modules/clients-info/ClientsInfoState';
import {
  IDataSearchParamsRequest,
  ITransferSummaryDataRequest,
  IUserTransactionsSummaryItem,
  PaymentMethodTypeEnum,
  TUserGameActionResponse
} from './schema';

/**
 * Get clients info.
 * @return Promise with clients info.
 */
export async function getClientsInfo(): Promise<ClientsInfoData> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/info/client-info'))
    .then(({ data }: AxiosResponse): ClientsInfoData => data)
    .catch((error: AxiosError) => {
      errorToastMessage(error);

      // Unknown unresolvable server-side error, see TP-1570
      if (error.response?.status === 400) {
        return clientsInfoStateDefaults.clientsInfo;
      }

      throw error;
    });
}

/**
 * Get users list.
 * @return Promise with user list data.
 */
export async function getUserList(
  params: UsersRequestParams,
  operatorId?: number
): Promise<Users> {
  return http
    .get(buildUrl(`/api/v1/${operatorId || '{operatorId}'}/users`), {
      params
    })
    .then(({ data }: AxiosResponse): Users => data);
}

/**
 * Activate mining profiting for user
 */
export async function startMiningProfit(userId: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/user/${userId}/mining-profit/start`)
  );
}

/**
 * Deactivate mining profiting for user
 */
export async function stopMiningProfit(userId: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/user/${userId}/mining-profit/stop`)
  );
}

/**
 * Allow on-chain transactions for user
 */
export async function startOnChain(userId: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/user/${userId}/on-chain/start`)
  );
}

/**
 * Forbid on-chain transactions for user
 */
export async function stopOnChain(userId: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/user/${userId}/on-chain/stop`)
  );
}

/**
 * Activate staking profiting for user
 */
export async function startStakingProfit(userId: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/user/${userId}/staking-profit/start`)
  );
}

/**
 * Deactivate staking profiting for user
 */
export async function stopStakingProfit(userId: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/user/${userId}/staking-profit/stop`)
  );
}

/**
 * Get user details.
 * @param userId user id.
 */

export async function getUserDetails(userId: number): Promise<UserDetails> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/users/${userId}`))
    .then(({ data }: AxiosResponse): UserDetails => data);
}

/**
 * Get user balances.
 * @param userId user id.
 */

export async function getUserBalances(
  userId: number
): Promise<UserBalancesData> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/users/${userId}/balance`))
    .then(({ data }: AxiosResponse): UserBalancesData => data);
}

/**
 * Get user transactions summary.
 * @param userId user id.
 * @param searchParams
 */
export async function getUserTransactionsSummary(
  userId: number,
  searchParams: ITransferSummaryDataRequest
): Promise<IUserTransactionsSummaryItem[]> {
  return http
    .get(
      buildUrl(`/api/v1/{operatorId}/users/${userId}/transactions-summary-V2`),
      {
        params: { ...searchParams }
      }
    )
    .then(
      ({ data }: AxiosResponse): IUserTransactionsSummaryItem[] => data || {}
    );
}

/**
 * Get user game actions summary.
 * @param userId user id.
 * @param searchParams
 */
export async function getUserGameActionsSummary(
  userId: number,
  searchParams: IDataSearchParamsRequest
): Promise<TUserGameActionResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/users/${userId}/game-action-summary`), {
      params: { ...searchParams }
    })
    .then(({ data }: AxiosResponse): TUserGameActionResponse => data || {});
}

/**
 * Transfer tokens from User to Operator's wallet.
 * @param userId user id.
 * @param data amount of tokens.
 */
export async function transferFromUser(
  userId: number,
  data: UserTransferBalanceData
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/users/${userId}/transfer-from-user`),
    {
      ...data
    }
  );
}

/**
 * Transfer tokens from Operator to user's wallet
 * @param userId user id.
 * @param data
 */
export async function transferToUser(
  userId: number,
  data: UserTransferBalanceData
): Promise<unknown> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/users/${userId}/transfer-to-user`),
    {
      ...data
    }
  );
}

/**
 * Set a limit programs coefficient
 */
export async function setCoefficient(
  userId: number,
  amount: number
): Promise<unknown> {
  return http.patch(
    buildUrl(`/api/v1/{operatorId}/users/${userId}/coefficient/${amount}`)
  );
}

/**
 * Set a token pre sale coefficient
 */
export async function setTokenPreSale(
  userId: number,
  coefficient?: number | null,
  depositLimit?: number | null
): Promise<unknown> {
  return http.put(
    buildUrl(`/api/v1/{operatorId}/promo-campaign/token-pre-sale`),
    null,
    {
      params: {
        coefficient,
        depositLimit,
        userId
      }
    }
  );
}

/**
 * Set a limit programs coefficient
 */
export async function setLimits(
  userId: number,
  type: string,
  amount: number
): Promise<unknown> {
  return http.patch(
    buildUrl(`/api/v1/{operatorId}/users/${userId}/limits/${amount}`),
    null,
    {
      params: {
        type
      }
    }
  );
}

/**
 * Get withdraw/deposit limits
 */
export async function getUserTransactionLimits(
  userId: number
): Promise<UserTransactionLimit> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/users/${userId}/payment-limit`))
    .then(({ data }: AxiosResponse): UserTransactionLimit => data);
}

/**
 * Update withdraw/deposit limits
 */
export async function updateUserTransactionLimits(
  data: CurrentUserTransactionLimit & { userId: number }
): AxiosPromise<CurrentUserTransactionLimitResponse> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/payment-method/user/${data.userId}`),
    data
  );
}

/**
 * Sync user balance with operator.
 * @param userId operator user id.
 */
export async function syncUserBalance(userId: number): Promise<unknown> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/users/${userId}/sync-balance`)
  );
}

/**
 * Delete all users limits
 */
export async function deleteAllUsersTransactionLimits(
  type: PaymentMethodTypeEnum
): Promise<unknown> {
  return http.delete(buildUrl(`/api/v1/{operatorId}/payment-method/user`), {
    params: type
  });
}

/**
 * Delete current users limit
 */
export async function deleteUserTransactionLimits(
  userId: string,
  type: PaymentMethodTypeEnum
): Promise<unknown> {
  return http.delete(
    buildUrl(`/api/v1/{operatorId}/payment-method/${type}/user/${userId}`)
  );
}

/**
 * Get transfer reasons.
 */
export async function getUserTransferReasons(
  userId: number
): Promise<UserTransferReason[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/users/${userId}/transfer-reasons`))
    .then(({ data }: AxiosResponse): UserTransferReason[] => data);
}

export const getWidgetUrl = (
  operatorUserId: string,
  apiKey: string
): Promise<{ url: string }> => {
  return http
    .get(`/api/v2/user/${operatorUserId}/widget`, {
      headers: { 'X-API-KEY': apiKey }
    })
    .then(({ data }: AxiosResponse<{ url: string }>) => data);
};
