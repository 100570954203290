export function required(v: string, label: string = 'Value'): boolean | string {
  return !!v || v == '0' || `${label} is required`;
}
export function positiveNumber(v: string): boolean | string {
  return +v > 0 || 'Value should be positive number';
}
export function validNumber(
  v: string,
  errorMessage?: string
): boolean | string {
  return (
    (!/^\s*$/.test(v) && !isNaN(+v) && (!v || v[v.length - 1] !== '.')) ||
    errorMessage ||
    'Value should be a valid number'
  );
}
export function minLength(v: string, length = 3): boolean | string {
  return (v && v.length >= length) || `Minimum ${length} characters length`;
}
export function maxLength(v: string, length = 3): boolean | string {
  return (v && v.length <= length) || `Max ${length} symbols.`;
}
export function hexColor(v: string): boolean | string {
  return (
    /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(v) ||
    'Value should have hex format'
  );
}
export function metaMaskPublicKey(v: string): boolean | string {
  return /^0x[0-9A-Fa-f]{40}$/.test(v) || 'Value should have valid format';
}

export function length(v: string, length = 3): boolean | string {
  return (
    (v && v.length === length) || `Value should consist ${length} characters`
  );
}

export function integer(v: string): boolean | string {
  return Number.isInteger(+v) || `Value should be integer number`;
}

export function gte(
  v: number,
  border = 0,
  errorMessage?: string
): boolean | string {
  return (
    Number(v) >= Number(border) ||
    errorMessage ||
    `Value should be >= ${border}`
  );
}

export function maxPrecision(v: number, precision = 0): boolean | string {
  const str = String(v);

  if (!str.includes('.')) {
    return true;
  }

  return (
    str.split('.')[1]?.length <= precision ||
    `Max ${precision} digits after floating point`
  );
}

export function lte(
  v: number,
  border = 1000,
  errorMessage?: string
): boolean | string {
  return v <= border || errorMessage || `Value should be <= ${border}`;
}

export function isValidUrl(url: string, httpUrl: boolean = false): boolean {
  const protocolPart: string = httpUrl ? 'https?:\\/\\/' : '^(?!https?://)';

  const pattern: string = `^${protocolPart}([a-zA-Z\\d][a-zA-Z\\d-]*[a-zA-Z\\d]|[a-zA-Z\\d])\\.(?:[a-zA-Z\\d-]+\\.)*[a-zA-Z\\d-]{2,}(?:\\/[a-zA-Z\\d-\\/]*)?$`;

  return new RegExp(pattern).test(url);
}

export function isValidMediaUrl(url: string): boolean {
  return new RegExp(
    `^(https?:\\/\\/([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}([a-zA-Z0-9-._~:/?#[\\]@!$&'()*+,;=]*)?|viber:\\/\\/[a-zA-Z0-9-._~:/?#[\\]@!$&'()*+,;=]*|([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})$`
  ).test(url);
}

export function mediaUrl(str: string): boolean | string {
  return isValidMediaUrl(str) || 'Please enter valid URL';
}

export function url(str: string): boolean | string {
  return isValidUrl(str, true) || 'Please enter valid URL';
}

export function urlWithoutProtocol(str: string): boolean | string {
  return isValidUrl(str) || 'Please enter valid URL';
}

export function time(v: string): boolean | string {
  return (
    /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g.test(v) ||
    'Format should be HH:mm'
  );
}

export const isSubnetMask = (mask: string, ipv6: boolean = false): boolean => {
  const subnetMaskRegex = ipv6 ? /^(\d{1,3})$/ : /^(\d{1,2})$/;

  return (
    subnetMaskRegex.test(mask) &&
    Number(mask) >= 0 &&
    Number(mask) <= (ipv6 ? 128 : 32)
  );
};

export const testIpAddress = (
  address: string,
  regexp: RegExp,
  allowSubnetMask: boolean = false,
  isIpv6: boolean = false
): boolean => {
  const [ipAddress, subnetMask] = address.split('/');

  if (!allowSubnetMask && subnetMask) return false;

  if (!regexp.test(ipAddress)) return false;

  const needToCheckSubnetMask = allowSubnetMask && subnetMask !== undefined;

  return needToCheckSubnetMask ? isSubnetMask(subnetMask, isIpv6) : true;
};

export const isIpv4Address = (
  address: string,
  allowSubnetMask: boolean = false
): boolean =>
  testIpAddress(
    address,
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
    allowSubnetMask
  );

export const isIpv6Address = (
  address: string,
  allowSubnetMask: boolean = false
): boolean =>
  testIpAddress(
    address,
    /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$|^(([0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4})?::(([0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4})?$/,
    allowSubnetMask,
    true
  );

export const isIpAddress = (
  address: string,
  allowSubnetMask: boolean = true
): boolean =>
  address &&
  (isIpv4Address(address, allowSubnetMask) ||
    isIpv6Address(address, allowSubnetMask));

export const ip = (
  address: string,
  allowSubnetMask: boolean = true
): boolean | string =>
  isIpAddress(address, allowSubnetMask) || 'Invalid IP format';

export function uuidv4(v: string): boolean | string {
  return (
    (v &&
      /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        v
      )) ||
    'Invalid format'
  );
}

export const isEmail = (s: string): boolean | string => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(s) || 'E-mail must be valid';
};

export const isLatinLetter = (s: string): boolean | string => {
  const latinRegex = /[a-zA-Z]$/;

  return latinRegex.test(s) || 'Only latin letters available';
};

export const validByRegExp = (
  s: string,
  regExp = /^[\da-zA-Z-]*$/i,
  fallbackMessage = 'Only latin letters, numbers and "-" are available'
): boolean | string => {
  if (typeof s === 'string') {
    const formattedValue = s.replaceAll(' ', '');
    const result = formattedValue?.length && regExp.test(formattedValue);
    if (result) return result;
  }

  return fallbackMessage;
};

const regexUtil = (v: string, regexp: RegExp): boolean => regexp.test(v);

export const hasDigits = (v: string): boolean => regexUtil(v, /(?=.*\d)/);

export const hasLowerAndUppercase = (v: string): boolean =>
  regexUtil(v, /(?=.*[a-z])(?=.*[A-Z])/);

export const noBlankSpacesAllowed = (v: string): boolean =>
  !regexUtil(v, /^\S*$/);

export const passwordValidationMessage = Object.freeze({
  blankSpace: 'No blank spaces allowed',
  lengthWithoutBlankSpaces: (v = 8) =>
    `At least ${v} characters without spaces`,
  length: (v = 8) => `At least ${v} characters`,
  digits: 'At least one digit',
  lowerAndUpperCase: 'At least one uppercase and one lowercase letter'
});

export const passwordValidator = (
  password: string,
  length = 8
): boolean | string => {
  if (noBlankSpacesAllowed(password)) {
    return passwordValidationMessage.blankSpace;
  }
  if (!hasDigits(password)) {
    return passwordValidationMessage.digits;
  }

  if (!hasLowerAndUppercase(password)) {
    return passwordValidationMessage.lowerAndUpperCase;
  }

  return password?.length >= length || passwordValidationMessage.length(length);
};

export const fileMaxSize = (
  file: Blob,
  maxByteSize: number = 1024 * 1024,
  errorMessage: string = 'File exceeds size limit'
): boolean | string => {
  return !file || file.size <= maxByteSize ? true : errorMessage;
};
