import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  AccountBalance,
  OperatorItem,
  AvgNgrStatsItem,
  AvgUsersNGRStatsItem,
  TransferPayload,
  WithdrawWalletPayload,
  WithdrawWalletResponce,
  PageData,
  WalletTransactionsResponce,
  ProfitStatisticData,
  RewardsInfo,
  UserGraphRequest
} from './schema';
import { makeSharedRequest } from '@/helpers/requestHelpers';
/**
 * get account balance
 * @return data with account balance
 */

export async function accountBalance(): Promise<AccountBalance> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/info/account-balances'))
    .then(({ data }: AxiosResponse): AccountBalance => data);
}

/**
 * get operator wallets
 * @return data array with operator information
 */

export async function operator(): Promise<OperatorItem[]> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/wallet'))
    .then(({ data }: AxiosResponse): OperatorItem[] => data);
}

/**
 * Transfer tokens between operator wallets.
 */
export async function transferTokens(
  payload: TransferPayload
): Promise<unknown> {
  return http.post(buildUrl(`/api/v1/operator/{operatorId}/transfer`), payload);
}

/**
 * Withdraw tokens from operator wallets.
 */
export async function withdrawTokens(
  payload: WithdrawWalletPayload
): Promise<unknown> {
  return http
    .post(buildUrl(`/api/v1/operator/{operatorId}/crypto-withdraw`), payload)
    .then(({ data }: AxiosResponse): WithdrawWalletResponce => data);
}

/**
 * Get transactions by operator from master wallet.
 */
export async function getMasterWalletTransactions(
  data: PageData
): Promise<unknown> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/transaction/inside`), {
      params: data
    })
    .then(({ data }: AxiosResponse): WalletTransactionsResponce => data);
}

/**
 * Get AVG ngr (stake, not stake).
 */
export async function getAvgNgrStats(
  requestData: UserGraphRequest
): Promise<AvgNgrStatsItem[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/dashboard-statistic/ngr-stats`), {
      params: {
        ...requestData
      }
    })
    .then(({ data }: AxiosResponse): AvgNgrStatsItem[] => data);
}

/**
 * Get AVG ngr (stake, not stake).
 */
export async function getUserStakeStats(
  requestData: UserGraphRequest
): Promise<AvgUsersNGRStatsItem[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/dashboard-statistic/user-stats`), {
      params: {
        ...requestData
      }
    })
    .then(({ data }: AxiosResponse): AvgUsersNGRStatsItem[] => data);
}

/**
 * Get profit history.
 */
export async function getProfitStatistic(
  from: string,
  to: string
): Promise<ProfitStatisticData> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/info/profit-statistic`), {
      params: {
        from,
        to
      }
    })
    .then(({ data }: AxiosResponse): ProfitStatisticData => data);
}

export async function getRewardsInfo(
  from: string,
  to: string
): Promise<RewardsInfo> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/dashboard-statistic/rewards'), {
      params: {
        from,
        to
      }
    })
    .then(({ data }: AxiosResponse): RewardsInfo => data);
}
export const getRewardsInfoShared = makeSharedRequest(getRewardsInfo);
