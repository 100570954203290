import { http } from './Connect';
import type { AxiosResponse } from 'axios';
import type {
  AccountsOperatorsItem,
  AccountUser,
  OperatorUserCreatePayload,
  SuperAdminGameProvider,
  SuperAdminGameProviderData,
  SuperAdminGameProviderAccount,
  ResponseObject,
  AccountItem,
  AccountsParams,
  DeleteRequestData,
  AccountUserUpdatePayload,
  SuperAdminOperatorInvoicePayload,
  OperatorInvoiceHistory,
  ISuperAdminOperator,
  IGenerateInvoiceParams,
  SuperAdminAccountsPayload,
  SuperAdminAccountsResponse
} from '@/api/schema';
import { buildUrl } from '@/helpers/buildUrl';

/**
 * Get all operators users.
 * @return Promise all operators users data.
 */
export async function getOperatorManagementUsers(
  id: number
): Promise<AccountUser[]> {
  return http
    .get(
      buildUrl(`/api/v2/super-admin/users/account/${id}/operator/{operatorId}`)
    )
    .then(({ data }: AxiosResponse): AccountUser[] => data);
}

/**
 * Get operators.
 * @return Promise with operators data.
 */
export async function getOperators(
  status?: string
): Promise<AccountsOperatorsItem[]> {
  return http
    .get('/api/v1/super-admin/operators/onboarding', { params: { status } })
    .then(({ data }: AxiosResponse): AccountsOperatorsItem[] => data);
}

/**
 * Delete operator user.
 * @param accountId account id.
 */
export async function deleteOperatorManagerUser({
  userId,
  accountId
}: DeleteRequestData): Promise<unknown> {
  return http.delete(
    `/api/v2/super-admin/users/${userId}/account/${accountId}`
  );
}

/**
 * Create operator user.
 * @param userData data for new operator user.
 */
export async function createOperatorManagerUser(
  userData: OperatorUserCreatePayload
): Promise<AccountUser> {
  return http
    .post(`/api/v2/super-admin/users`, userData)
    .then(({ data }: AxiosResponse): AccountUser => data);
}

/**
 * Update operator user.
 * @param data new Account User data for user update.
 */
export async function updateOperatorManagerUser(
  data: AccountUserUpdatePayload
): Promise<AccountUser> {
  const { userId, ...userData } = data;
  return http
    .put(`/api/v2/super-admin/users/${userId}`, { ...userData })
    .then(({ data }: AxiosResponse): AccountUser => data);
}

/**
 * Get operator by id.
 * @param id operator id.
 */
export async function getOperatorById(
  id: number
): Promise<AccountsOperatorsItem> {
  return http
    .get(`/api/v1/super-admin/operators/${id}/onboarding`)
    .then(({ data }: AxiosResponse): AccountsOperatorsItem => data);
}

/**
 * Get game providers.
 */
export async function getSuperAdminGameProviders(): Promise<
  SuperAdminGameProvider[]
> {
  return http
    .get(`/api/v1/super-admin/game-provider`)
    .then(({ data }: AxiosResponse): SuperAdminGameProvider[] =>
      data.sort(function (
        a: SuperAdminGameProvider,
        b: SuperAdminGameProvider
      ) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    );
}

/**
 * Get game providers accounts.
 */
export async function getSuperAdminGameProvidersAccounts(params: {
  page: number;
  size: number;
}): Promise<{
  content: SuperAdminGameProviderAccount[];
  totalElements: number;
}> {
  return http.get(`/api/v1/super-admin/game-provider/account`, { params }).then(
    ({
      data
    }: AxiosResponse): {
      content: SuperAdminGameProviderAccount[];
      totalElements: number;
    } => data
  );
}

/**
 * Create game provider account.
 */
export async function createSuperAdminGameProvidersAccounts(body: {
  providerName: string;
  email: string;
}): Promise<SuperAdminGameProviderAccount> {
  return http
    .post(`/api/v1/super-admin/game-provider/account`, body)
    .then(({ data }: AxiosResponse): SuperAdminGameProviderAccount => data);
}

/**
 * Enable/disable game provider for all operators.
 * @param name provider name.
 * @param enabled enabled status.
 */
export async function updateSuperAdminGameProviders(
  name: string,
  enabled: boolean
): Promise<unknown> {
  return http
    .post(`/api/v1/super-admin/game-provider/${name}`, null, {
      params: { enabled }
    })
    .then(({ data }: AxiosResponse): unknown => data);
}

/**
 * Get game providers operators.
 * @param name provider name.
 */
export async function getSuperAdminGameProviderOperators(
  name: string
): Promise<SuperAdminGameProviderData> {
  return http
    .get(`/api/v2/super-admin/game-provider/${name}`)
    .then(({ data }: AxiosResponse): SuperAdminGameProviderData => data);
}

/**
 * Enable/disable (toggle) operator IP
 * @param id id of operator
 * @param idIp id of ip address
 */
export async function toggleOperatorIPStatus(
  id: number,
  idIp: number
): Promise<any> {
  return http.get(`/api/v1/super-admin/operators/${id}/operator-ip`, {
    params: { idIp }
  });
}

/**
 * Get account items
 */
export async function accounts(
  params: AccountsParams
): Promise<ResponseObject<AccountItem>> {
  return http
    .get(`/api/v2/super-admin/account`, {
      params: {
        ...params
      }
    })
    .then(
      ({
        data
      }: AxiosResponse<
        ResponseObject<AccountItem>
      >): ResponseObject<AccountItem> => data
    );
}

/**
 * Get super-admin accounts with operators
 */
export async function getSuperAdminAccounts(
  payload: SuperAdminAccountsPayload
): Promise<SuperAdminAccountsResponse> {
  return http
    .get(`/api/v3/super-admin/account`, {
      params: {
        ...payload
      }
    })
    .then(
      ({
        data
      }: AxiosResponse<SuperAdminAccountsResponse>): SuperAdminAccountsResponse =>
        data
    );
}

/**
 * Get account item by ID
 */
export async function getAccountByID(id: number): Promise<AccountItem> {
  return http
    .get(`/api/v1/super-admin/account/${id}`)
    .then(({ data }: AxiosResponse<AccountItem>): AccountItem => data);
}

/**
 * Enable/Disable operator withdrawal.
 */
export async function changeOperatorFinanceFlow(
  id: number,
  enabled: boolean = false
): Promise<unknown> {
  return http.patch(
    `/api/v1/super-admin/operators/${id}/enable-operator-withdrawal`,
    null,
    {
      params: { enabled }
    }
  );
}

/**
 * Deploy smart contract
 * @param id operator id
 */

export async function deploySmartContract(id: number): Promise<unknown> {
  return http.post(`/api/v1/super-admin/operators/${id}/deploy-smart-contract`);
}

/**
 * Update account brand name.
 */
export async function updateAccountBrandName(
  id: number,
  brandName: string
): Promise<AccountItem> {
  return http
    .put(`/api/v1/super-admin/account/${id}/brandName`, { brandName })
    .then(({ data }: AxiosResponse<AccountItem>): AccountItem => data);
}

/**
 * Update account role.
 */
export async function updateAccountRole(
  id: number,
  copyStake: boolean
): Promise<unknown> {
  return http.put(`/api/v1/super-admin/account/${id}/role`, null, {
    params: { copyStake }
  });
}

/**
 * Delete account.
 */
export async function deleteAccount(id: number): Promise<AccountItem> {
  return http
    .delete(`/api/v1/super-admin/account/${id}`)
    .then(({ data }: AxiosResponse<AccountItem>): AccountItem => data);
}

/**
 * Delete operator.
 */
export async function deleteOperator(
  accountId: number,
  operatorId: number
): Promise<unknown> {
  return http.delete(
    `/api/v1/super-admin/account/${accountId}/operator/${operatorId}`
  );
}

/**
 * Get invoices.
 */
export async function getSuperAdminInvoices(
  payload: SuperAdminOperatorInvoicePayload
): Promise<OperatorInvoiceHistory> {
  return http
    .get(`/api/v1/balance/invoices/all`, {
      params: { ...payload },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): OperatorInvoiceHistory => data);
}

/**
 * Get super admin invoice PDF report.
 * @return Promise with invoice PDF report.
 * @param invoiceId Invoice id.
 */
export async function getSuperAdminInvoiceReportPdf(
  invoiceId: number
): Promise<any> {
  return http.get(`/api/v1/balance/invoice/${invoiceId}/pdf`, {
    responseType: 'blob'
  });
}

/**
 * Get super admin operators list.
 * @return Promise operators list.
 */
export async function getSuperAdminOperators(): Promise<ISuperAdminOperator[]> {
  return http
    .get('/api/v1/balance/operators')
    .then(({ data }: AxiosResponse): ISuperAdminOperator[] => data);
}

/**
 * Generate invoice file.
 * @return Promise operators list.
 */
export async function generateInvoiceOperator(
  params: IGenerateInvoiceParams
): Promise<string> {
  return http
    .post('/api/v1/balance/invoice', { ...params })
    .then(({ data }: AxiosResponse): string => data);
}

/**
 * Update invoice status
 * @param invoiceId invoice id.
 * @param status invoice status.
 */
export async function updateSuperAdminInvoiceStatus(
  invoiceId: number,
  status: string
): Promise<void> {
  return http.patch(`/api/v1/balance/invoice/${invoiceId}`, null, {
    params: { status }
  });
}

/**
 * Update invoice status
 * @param invoiceId invoice id.
 */
export async function deleteSuperAdminInvoice(
  invoiceId: number
): Promise<string> {
  return http
    .delete(`/api/v1/balance/invoice/${invoiceId}`)
    .then(({ data }: AxiosResponse<string>): string => data);
}
